@tailwind base;
@tailwind components;
@tailwind utilities;

@font-face {
    font-family: 'SF Pro Text';
    src: url(/public/fonts/SF-Pro-Text-Regular.otf);
    font-weight: 400;
}

.sfPro {
    font-family: 'SF Pro Text' !important;
}


.set-height-pos {
    height: 480px !important;
    position: absolute !important;
}

.set-top-pos {
    top: -140px;
}

@media (-webkit-device-pixel-ratio : 1.25) {

    .set-height-pos {
        height: 400px !important;
    }

    .set-top-pos {
        top: -180px;
    }

}

@media (-webkit-device-pixel-ratio : 1.5) {

    .set-height-pos {
        height: 400px !important;
    }

    .set-top-pos {
        top: -180px;
    }

}