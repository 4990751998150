@tailwind base;
@tailwind components;
@tailwind utilities;

@font-face {
    font-family: 'GT Walsheim Pro';
    src: url(/public/fonts/GTWalsheimPro/regular.ttf);
    font-weight: 400;
}

@font-face {
    font-family: 'GT Walsheim Pro Medium';
    src: url(/public/fonts/GTWalsheimPro/medium.ttf);
    font-weight: 500;
}

@font-face {
    font-family: 'GT Walsheim Pro Medium';
    src: url(/public/fonts/GTWalsheimPro/medium.ttf);
    font-weight: 500;
}

@font-face {
    font-family: 'GT Walsheim Pro Bold';
    src: url(/public/fonts/GTWalsheimPro/bold.ttf);
    font-weight: 700;
}

body {
    font-family: 'GT Walsheim Pro' !important;
}

.font-normal {
    font-family: 'GT Walsheim Pro' !important;
}

.font-medium {
    font-family: 'GT Walsheim Pro Medium';
}

.font-bold {
    font-family: 'GT Walsheim Pro Bold';
}


.set-height-pos {
    height: 480px !important;
    position: absolute !important;
}

.set-top-pos {
    top: -140px;
}

@media (-webkit-device-pixel-ratio : 1.25) {

    .set-height-pos {
        height: 400px !important;
    }

    .set-top-pos {
        top: -180px;
    }

}

@media (-webkit-device-pixel-ratio : 1.5) {

    .set-height-pos {
        height: 400px !important;
    }

    .set-top-pos {
        top: -180px;
    }

}




@media(min-width:150px){
    .not-lang.facebook-title{
        font-size: 13px;
    }
    
    .not-lang.facebook-description{
        font-size: 11px;
    }

    .not-lang.go-to-page{
        font-size: 11px;
    }
}

@media(min-width:220px){
    .not-lang.facebook-title{
        font-size: 14px;
    }
    
    .not-lang.facebook-description{
        font-size: 12px;
        line-height: 1.4;
    }

    .not-lang.go-to-page{
        font-size: 11px;
    }
}

@media(min-width:298px){
    .not-lang.facebook-title{
        font-size: 15px;
    }
    
    .not-lang.facebook-description{
        font-size: 13px;
    }

    .not-lang.go-to-page{
        font-size: 12px;
    }
}

@media(min-width:400px){
    .not-lang.facebook-title{
        font-size: 28px;
    }
    
    .not-lang.facebook-description{
        font-size: 14px;
    }

    .not-lang.go-to-page{
        font-size: 14px;
    }
}