.react-pdf__Page__canvas{
    max-width: 342px;
    width: inherit !important;
    height: auto !important;
}

@media (max-width: 575px){
    .react-pdf__Page__canvas{
        max-width: 100% !important;
        width: inherit !important;
        height: auto !important;
    }
}