.styles-module_close__2I1sI {
    color: black !important;
    opacity: 1 !important;
    font-size: 50px !important;
    line-height: 30px !important;
    text-shadow: -2px 0 white, 0 2px white, 2px 0 white, 0 -2px white !important;
    
}

.styles-module_navigation__1pqAE{
    opacity: 1 !important;

}
.styles-module_content__2jwZj{
    width: 100% !important;
}