body {
  margin: 0;
  /* font-family: Vazir, -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif; */
    font-family: 'Eudoxus Sans', sans-serif;

  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: white;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.react-pdf__Page > canvas{
  max-width: none;
}

.wrap-overflow-text{
      overflow-wrap: break-word;
    display: inline-block;
}

.step-def-text{
  font-size: clamp(24px, 1.8vw, 28px);
  line-height: 1.25;

}

.showcase{
  overflow:hidden;
  pointer-events:none;
}

.b-share{
  max-height: 350px;
  overflow-y: auto;
}

/* width */
.custom-scroll-bar::-webkit-scrollbar {
  width: 5px;
}

/* Track */
.custom-scroll-bar::-webkit-scrollbar-track {
  background: #f1f1f1;
}

/* Handle */
.custom-scroll-bar::-webkit-scrollbar-thumb {
  background: #888;
  border-radius: 5px;
}

/* Handle on hover */
.custom-scroll-bar::-webkit-scrollbar-thumb:hover {
  background: #555;
}
