.btn-star {
    background-color: transparent;
    border: 0;
}

.rating-star {
    width: 100%;
    display: flex;
    justify-content: space-between;
}

.MuiRating-label.MuiRating-pristine.css-dqr9h-MuiRating-label {
    width: 0;
    display: none;
}

.rating-star label {
    /* padding-left: 24px; */
}

.rating-star label span {
    font-size: 48px;
}

.rating-star label span svg {
    stroke: 0;
}

.save-more {
    border-radius: 30px;
    color: #1D59F9;
    border: 2px solid #1D59F9;
    box-shadow: none;
    background-color: #FFF;
    /* padding-left: 32px;
    padding-right: 32px; */
}

.send-feedack {
    /* padding-left: 32px;
    padding-right: 32px; */
    border-radius: 32px;
    border: 2px solid #1D59F9;


}

.modal-body {
    padding: 40px 32px 24px;
}

.modal-content,
.modal-card {
    margin: auto;
}

@media (min-width: 576px) {
    .main-card-wrap {
        max-width: 480px;
    }
}

@media (max-width: 575.5px) {
    .main-card-wrap {
        max-width: 320px;
    }
}

.see-more-btn {
    width: 135px;
    border: 1px solid #FFFFFF;
    height: 36px;
    display: flex;
    font-size: 13px;
    align-items: center;
    font-weight: 500;
    line-height: 30px;
    border-radius: 34px;
    justify-content: center;
    background-color: #88825F !important;
}

.see-more-btn:hover {
    border: 1px solid #FFFFFF;
}

.app-card-wrapper {
    padding: 80px 20px 40px;
}

@media (max-width:575px) and (max-height:811.8px) {
    .app-card-wrapper {
        padding: 80px 8px 40px;
    }
}

.tooltip {
    --bs-tooltip-bg: #26162E !important;
}

.styles-module_image__2hdkJ {
    background-color: #FFF;
}

.swiper-slide img {
    background-color: #FFF;
}

.default-section{
    margin-top: 5%;
    margin-left: 15%;
    margin-right: 15%;
}

/* desktop */
@media only screen and (max-width:1440px){
    .default-section{
        margin-top: 9%;
        margin-left: 10%;
        margin-right: 10%;
    }
    .left-side>img{
        width: 19rem;
    }
    .left-side .title{
        font-size: 35px;
    }
    .left-side .para{
        font-size: 18px;
    }
}

/* laptop */
@media only screen and (max-width:1024px){
    .default-section{
        margin-top: 12%;
        margin-left: 5%;
        margin-right: 5%;
    }
    .left-side>img{
        width: 14rem;
    }
    .left-side .title{
        font-size: 25px;
    }
    .left-side .para{
        font-size: 17px;
    }
    .left-side .create-btn{
        padding-top: 20px;
        padding-bottom: 20px;
    }
    .right-side .qr-cards{
        width: 360px;
    }
    .right-side .right-title{
        font-size: 37px;
    }
    .right-side{
        max-height: 559px !important;
    }
    .right-side .qr-card-text{
        left: 8.5%;
        top: 40%;
    }
}

/* tab */
@media only screen and (max-width:768px){
    .default-section{
        margin-top: 15%;
    }
    .section{
        flex-direction: column !important;
    }
    .left-side .title{
        font-size: 38px;
    }
    .left-side .create-btn{
        align-self: center;
    }
    .right-side .right-title{
        font-size: 32px;
        padding-top: 20px;
    }
    .right-side{
        margin-top: 65px;
        max-height: 509px !important;
    }
    .df-footer>div{
        font-size: 13px;
        font-weight: bold !important;
    }
    .df-section>a{
        font-size: 14px;
        /* font-weight: bold !important; */
        line-height: 1.25;
        margin-left: 0;
    }
}

@media(max-height:800px){
    .defualt-page{
        margin-top: 25px !important;
        margin-bottom: 25px !important;
        max-height: 100% !important;
    }
}

/* mobile */
@media  only screen and (max-width:425px) ,
        screen and (device-width: 428px) and (device-height: 926px) and (-webkit-device-pixel-ratio: 3),
        screen and (max-width:575px) and (max-height:932px)
    {
    .section{
        flex-direction: column !important;
    }
    .left-side>img{
        width: 10rem;
    }
    .left-side .title{
        font-size: 25px;
    }
    .left-side .para{
        font-size: 15px;
    }
    .left-side .create-btn{
        padding-top: 20px;
        padding-bottom: 20px;
        align-self: center;
        font-size: 17px;
    }
    .right-side .qr-cards{
        width: 260px;
    }
    .right-side .right-title{
        font-size: 26px;
        padding-top: 20px;
        text-align: center;
    }
    .right-side{
        display: none !important;
        margin-top: 65px;
        max-height: 439px !important;
        padding: 18px !important;
    }
    .df-footer{
        flex-direction: column !important;
    }
    .df-footer>div{
        padding-bottom: 10px;
    }
}

.footer-logo:hover{
    color: #000000 !important;
}