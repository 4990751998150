@tailwind base;
@tailwind components;
@tailwind utilities;


:root {
    scroll-behavior: unset;
    scrollbar-width: none;
}

.invisible-scroll::-webkit-scrollbar {
    display: none;
}

.react-simple-image-viewer__close{
    padding:5px;
    z-index: 100;
}